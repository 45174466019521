import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_CHAT_CONVERSATIONS, ADMIN_MODAL_CONVERSATION_CREATE } from 'Consts/routes';

import { parseToQueryString, parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import ConnverstionsList from 'Components/admin/chat/ConversationsList';
import ConversationsUsersList from 'Components/admin/chat/ConversationsUsersList';

export const TAB_CREATE = 'create';
export const TAB_LIST = 'list';
export const TAB_USERS = 'users';

export default class AdminChatConversations extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-chat-conversations"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_CHAT_CONVERSATIONS.path, {}, { tab: TAB_LIST }),
                        label: 'Konwersacje',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Konwersacje"
                        controls={[{
                            key: TAB_CREATE,
                            label: 'Nowa konwersacja',
                            onClick: () => history.push(parseToQueryString(location.pathname, queryObject) + ADMIN_MODAL_CONVERSATION_CREATE),

                        }]}
                        tabs={[{
                            key: TAB_LIST,
                            label: 'Lista konwersacji',
                            children: (
                                <ConnverstionsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_USERS,
                            label: 'Użytkownicy',
                            children: (
                                <ConversationsUsersList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
