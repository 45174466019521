import { connect } from 'react-redux';
import Component from './component';

import {
    listAllConversations,
    enableConversation,
    disableConversation,
} from 'Redux/modules/admin/chat/actions';

export default connect(
    state => ({
        conversationsList: state.adminChat.conversationsList,
    }),
    dispatch => ({
        actions: {
            listAllConversations: dispatch(listAllConversations),
            enableConversation: dispatch(enableConversation),
            disableConversation: dispatch(disableConversation),
        },
    })
)(Component);
